import React, { Component } from 'react'

class Screen6A extends Component {

  render() {

    const { entry, addEntry } = this.props

    return (
      <main>
        <h3>Djelo motivirano mržnjom</h3>
        <label>Zašto mislite da se radi o djelu motiviranom mržnjom?&nbsp;*
          <input type="text" value={entry.why} onChange={(e) => addEntry('why', null, e)} />
        </label>
        <label>Koje su posljedice događaja (na žrtvu, na svjedoka, na imovinu i sl.)? *
          <input type="text" value={entry.consequences} onChange={(e) => addEntry('consequences', null, e)} />
        </label>
        <label>Jeste li prijavili počinjeno djelo nekom drugom (policija, organizacija, fizička osoba i sl.) i koji je ishod prijave? *
          <input type="text" value={entry.other_notification} onChange={(e) => addEntry('other_notification', null, e)} />
        </label>
      </main>
    );
  }
}

export default Screen6A;
