import React, { Component } from 'react'
import eu_logo from '../images/eu_logo.png'
import uzuvrh_logo from '../images/uzuvrh_logo.png'
import zagreb_logo from '../images/zagreb_logo.png'
// import riku_logo from '../images/riku_logo.png'
import finland_logo from '../images/finland_logo.png'
import kljp_logo from '../images/kljp_logo.png'
import gong_logo from '../images/gong_logo.png'
import cms_logo from '../images/cms_logo.png'
import centri_znanja_logo from '../images/centri_znanja_logo.png'
import zaklada_logo from '../images/zaklada_logo.png'
import norway_grants from '../images/norway_grants.png'
import fah_eu from '../images/fah_eu.jpeg'
import pride_logo from '../images/pride_logo.png'
import hrhf_logo from '../images/hrhf_logo.png'
class ScreenText extends Component {

  texts = [{
    title: "O govoru mržnje",
    content: <div className="faq"><h4>Što je govor mržnje?</h4>
              <p>Govor mržnje je oblik izražavanja kojim se <strong>javno</strong> (u medijima, na internetskim stranicama, na javnim površinama, na javnim skupovima i u javnosti) poziva na nasilje i mržnju prema nekoj skupini ili ljudima iz te skupine temeljem osnova kao što su nacionalnost, etničko porijeklo, boja kože, vjerska pripadnost, seksualna orijentacija, rodni identitet, zdravstveno stanje ili neka druga osobina.</p>
              <p>Takvo širenje nesnošljivosti prema drugim članovima društva zbog njihovog identiteta, društvenog položaja ili pripadnosti pojedinim skupinama negira temeljna načela jednakosti i ravnopravnosti ljudi na kojima počiva svako demokratsko društvo.</p>

              <h4>Zašto je govor mržnje štetan?</h4>
              <p>Razni oblici govora mržnje razorno djeluju na društvenu koheziju, vrijednosti pluralizma i tolerancije prema drugima i drugačijima.  Govor mržnje predstavlja zvono za društvenu uzbunu jer ukazuje na disbalans i netrpeljivost prema ili između određenih skupina u društvu. Reagirajući na taj alarm, možemo spriječiti daljnje nasilje (ili eskalaciju nasilja) – zločine iz mržnje.</p>
              <p>Govor mržnje također negativno utječe na slobodu govora, jer je upravo govor mržnje zloupotreba slobode govora zato što krši tuđa prava i potpuno je nespojiv s ravnopravnošću kao temeljnim načelom života u demokratskom društvu.</p>

              <h4>Zašto prijaviti govor mržnje?</h4>
              <p>Ako ne želite živjeti u društvu u kojem su mržnja i netrpeljivost prihvatljiva osnova za međuljudske odnose, i Vaš je doprinos važan.</p>
              <p>Prijavom dajete svoj obol tolerantnom i pluralnom društvu, u kojemu svatko ima pravo na ljudsko dostojanstvo i može neometano uživati sva svoja ljudska prava, neovisno o svojim osobinama. Ova načela su posebno važna u javnoj komunikaciji i dužnost je svih medija pridržavati ih se.</p></div>
  },{
    title: "Kako prijaviti?",
    content: <div>
              <p>Podnošenje prijava putem ove stranice vrlo je jednostavno – samo ispunite polja potrebna za prijavu događaja o kojem nas želite obavijestiti.</p>
              <p>Kako bismo mogli što bolje reagirati, pružiti vam što točniju informaciju što smo poduzeli ili što vi možete poduzeti, potrebno je pružiti nam što je moguće više informacija o događaju o kojem pišete. Nakon podnesene prijave, očekujte javljanje administratorskog tima koji će Vam pružiti informaciju o mogućnosti postupanja po Vašoj prijavi te, ukoliko bude potrebno, zatražiti dodatna objašnjenja.</p>
              <p><strong>VAŽNO</strong> – uz prijavu navedite Vašu stvarnu e-mail adresu, kako biste mogli odgovoriti na eventualna dodatna pitanja o Vašoj prijavi, ali i pratiti njezin status te dobiti povratnu informaciju o tome što se s Vašom prijavom događa. Svakako pratite Vaš e-mail kako biste na vrijeme pročitali sve daljnje obavijesti o Vašoj prijavi.</p>
             </div>,
  },{
    title: "Često postavljena pitanja",
    content: <div className="faq">
              <h4>1. Čemu služi ova stranica?</h4>
              <p>Stranica služi za prijavljivanje govora mržnje i djela motiviranih mržnjom.</p>
              <p><strong>Prijava govora mržnje</strong> - budući da različiti oblici govora mržnje ne potpadaju pod jedinstvenu pravnu regulativu već se nalaze u različitim propisima (koji se odnose na medije, kazneno pravo, suzbijanje diskriminacije itd.), svrha je ove stranice olakšati pristup intervenciji kod govora mržnje. Na Vama je kao građankama i građanima da na pojave govora mržnje u javnom prostoru upozorite, a naš je zadatak Vašu dojavu pretvoriti u oblik prijave po kojemu nadležna tijela mogu (i moraju) reagirati. No, cilj ove stranice nije samo intervencija s ciljem uklanjanja i sankcioniranja govora mržnje, nego i podizanje svijesti javnosti o takvom izražavanju kao nespojivom s demokratski uređenim društvom, otvorenom za sve ljude, neovisno o tome kojoj društvenoj skupini pripadaju.</p>
              <p><strong>Prijava djela motiviranih mržnjom</strong> - ova stranica vam može pomoći u pružanju informacija kome se obratiti za daljnju pomoć i podršku ukoliko ste žrtva djela motiviranog predrasudama i mržnjom.<br />
                Ukoliko ste napadnuti, ukoliko vam je oštećena imovina ili drugi osobni predmeti ili ste svjedočili takvom događaju te smatrate da je ono utemeljeno na predrasudi temeljenoj na određenim karakteristikama poput rasne pripadnosti, boje kože, vjeroispovijesti, nacionalnog ili etničkog podrijetla, jezika, invaliditeta, spola, spolnog opredjeljenja ili rodnog identiteta, javite se za pomoć i podršku u daljnjem postupanju i zaštiti.<br />
                Također, želimo osigurati bolje i posvećenije praćenje ovog područja i kvalitetnu provedbu politika zaštite za sve one koji bi mogli biti mete napada te nam pružene informacije u tome pomažu. Nakon postupanja, Vaša prijava bit će spremljena u bazi i korištena u statističke svrhe i za izradu godišnjeg izvještaja o postupanju.</p>

              <h4>2. Koja je točno definicija govora mržnje?</h4>
              <p>Postoji više definicija govora mržnje:</p>
              <p>Govor mržnje je javni govor – putem radija, tiska, televizije, računalnog sustava ili mreže, na javnom skupu, u statusima, objavama, lecima, slikama video I drugim materijalima koji su javno dostupni, a koji pozivaju na nasilje i mržnju prema skupini ljudi ili pripadniku neke skupine s obzirom na:</p>
              <ul>
                <li>rasnu, vjersku, nacionalnu ili etničku pripadnost, jezik, podrijetlo ili boju kože;</li>
                <li>spol, spolno opredjeljenje, rodni identitet, bračni ili obiteljski status;</li>
                <li>invaliditet;</li>
                <li>društveni položaj, imovinsko stanje, obrazovanje, zdravstveno stanje, dob.</li>
              </ul>
              <p>"Govor mržnje podrazumijeva sve oblike izražavanja kojima se šire, raspiruju ili opravdavaju rasna mržnja, ksenofobija, antisemitizam ili drugi oblici mržnje temeljeni na netoleranciji, uključujući pri tome i netoleranciju izraženu u obliku agresivnog nacionalizma i etnocentrizma, te diskriminaciju i neprijateljstvo prema manjinama, migrantima i osobama imigrantskog podrijetla." (Preporuka Odbora Ministara Vijeća Europe No.R (97) 20 od 30.10.1997.)</p>
              <p>"Govor mržnje je svaka forma izražavanja koja širi, potiče, promovira ili opravdava mržnju zasnovanu na netoleranciji, uključujući i vjersku netoleranciju." (Europski sud za ljudska prava)</p>

              <h4>3. Je li govor mržnje kažnjiv?</h4>
              <p>Na svaki govor mržnje treba reagirati, ali nije svaki takav govor kažnjiv pa razlikujemo:</p>
              <ul>
                <li>govor mržnje koji predstavlja kazneno djelo temeljem čl. 325 Kaznenog zakona &ndash; javno poticanje na nasilje i mržnju</li>
                <li>govor mržnje koji sankcionira upravno i građansko pravo, ali ne i kazneni zakon (propisan je u Zakunu o medijima, Zakonu o elektroničkim medijima, Zakonom o suzbijanju diskriminacije)</li>
                <li>govor koji negativno utječe na očuvanje tolerancije, međusobno uvažavanje i poštivanje prava drugih i koji podlježe primjeni etičkih standarda u javnoj komunikaciji, novinarstvu, te standardima zajdenice na društvenim mrežama</li>
              </ul>
              <p>Neki oblici neprihvatljivog govora su jasno i jednoznačno zabranjeni i sankcionirani Kaznenim zakonom, člankom 325 kao najrepresivnijim načinom obeshrabrivanja, sprječavanja i prevencije govora mržnje. To su najteži oblici poticanja na nasilje i mržnju. Drugi oblici su također zakonski regulirani i predviđene su druge sankcije, najčešće prekršajne. Govor mržnje je sankcioniran i kroz ovlasti nezavisnih regulatornih i samoregulatornih tijela (Vijeće za elektroničke medije, Novinarsko vijeće časti).</p>

              <h4>4. A što je sa slobodom govora?</h4>
              <p>Sloboda govora je jedno od najvažnijih demokratskih prava koje prepoznaje i niz nadnacionalnih i nacionalnih akata, od Međunarodnog pakta o građanskim i političkim pravima (čl. 38), preko Ustava Republike Hrvatske (čl. 19) pa do Zakona o medijima (čl. 3).</p>
              <p>No, sloboda govora nije neograničena i bezuvjetna. Sprječavanje govora mržnje i stigmatizacije pojedinih društvenih skupina jedan je od rijetkih opravdanih razloga za ograničavanje slobode javnog izražavanja. Govor kojim se stigmatizira, poziva na nasilje i mržnju ili huška na određene društvene skupine nije spojiv sa slobodom govora upravo zato što krši tuđa prava i potpuno je nespojiv s ravnopravnošću kao temeljnim načelom života u društvu.</p>

              <h4>5. Što su to djela motivirana mržnjom?</h4>
              <p>Nasilni incidenti i druga djela motivirana mržnjom definirani su u Kaznenom zakonu kao zločini iz mržnje - kao kaznena djela počinjena zbog rasne pripadnosti, boje kože, vjeroispovijesti, nacionalnog ili etničkog podrijetla, jezika, invaliditeta, spola, spolnog opredjeljenja ili rodnog identiteta druge osobe.</p>
              <p>Međutim, nije svako nasilje počinjeno radi navedenih karakteristika zločin iz mržnje u kaznenopravnom smislu. Ispravno kvalificiranje djela čija ste žrtva ili svjedok je zadatak nadležnih institucija - policije, tužiteljstva i sudova. Ako sumnjate da ste oštećeni (fizički napad, vaša imovina i sl.) ili ste svjedočili djelu za koje sumnjate da je motivirano nečijim karakteristikama iz gore navedene liste, javite se ili ovdje ili u najbližu policijsku postaju te zatražite postupanje i pomoć.</p>

              <h4>6. Hoće li moje ime/alias ili e-mail adresa biti objavljeni na ovoj stranici?</h4>
              <p>Neće. Vaše ime/alias i e-mail adresa bit će vidljivi isključivo administratorima te će služiti samo i isključivo tome da Vas oni mogu kontaktirati s ciljem dobivanja više informacija o prijavi koju podnosite, odnosno točnijeg utvrđivanja o kakvom se slučaju radi kako bi prema njemu, ako postoje osnove i dostatne informacije, mogli i postupiti. Niti jedan podatak o podnositelju/podnositeljici ijedne prijave na ovoj stranici nikad neće biti učinjen dostupnim javnosti.</p>

              <h4>7. Samo malo, zašto uopće moram ostaviti svoje podatke za kontakt?</h4>
              <p>Minimum je odgovornosti administratora ove stranice osiguravanje da iza primljenih prijava stoje stvarne osobe koje su spremne pružiti konkretne i vjerodostojne informacije o slučajevima koje prijavljuju. Kako ne bi bile podnošene prijave koje sadržajno ne omogućavaju smisleno postupanje, administratori od podnositelja prijava moraju zatražiti povratnu informaciju, a za koju je poželjno ostaviti stvarno ime i prezime, a neophodno ostaviti funkcionirajuću e-mail adresu. Budući da niti jedna prijava neće biti objavljena, kao ni bilo koja informacija o njoj, nitko se ne treba bojati pružanja svojih stvarnih podataka za kontakt. Šaljući nam prijavu, mi se obvezujemo na njezino čuvanje, a Vi bez rizika za sebe dajete svoj obol tolerantnijem i inkluzivnijem društvu.</p>

              <h4>8. Trebam li dati pristanak za pohranu i obradu osobnih podataka?</h4>
              <p>Brinemo o vašim osobnim podacima. Naše politika zaštite osobnih podataka usklađene su s odredbama Opće uredbe o zaštiti podataka (General Data Protection Regulation – GDPR, 95/46/EC). Svi osobni podaci prikupljaju se isključivo za potrebe postupanja po ovoj prijavi i neće biti korišteni za druge svrhe, niti dijeljeni s trećim stranama. Više informacija o osobnim podacima možete pronaći ovdje: <a href="https://bit.ly/36okD2t">https://bit.ly/36okD2t</a>.</p>

              <h4>9. Kojem tijelu/kojim tijelima odlaze podnesene prijave?</h4>
              <p>Ovisno o naravi svake pojedinačne prijave, ona može biti pretvorena u službeni podnesak nekome od sljedećih tijela javne vlasti:</p>
              <ul>
                <li>Agencija za elektroničke medije/Vijeće za elektroničke medije – Agencija i Vijeće su tijela nadležna za nadzor nad sadržajem emisija emitiranih na televiziji ili radiju te putem internetskih portala (elektroničkih publikacija).</li>
                <li>Pravobraniteljstva – Pučka pravobraniteljica, Pravobraniteljica za ravnopravnost spolova, Pravobraniteljica za osobe s invaliditetom i Pravobraniteljica za djecu, tijela su koja se bave zaštitom ljudskih prava (u slučaju triju tzv. posebnih pravobraniteljstava, ciljano zaštitom ljudskih prava određene društvene skupine) te sustavnim praćenjem i pružanjem preporuka za unapređenje stanja zaštite ljudskih prava u Republici Hrvatskoj.</li>
                <li>Novinarsko vijeće časti – Novinarsko vijeće časti samoregulatorno je tijelo novinarske struke koje prati poštivanje načela i normi Kodeksa časti hrvatskih novinara, a sastavni je dio Hrvatskog novinarskog društva (HND).</li>
              </ul>
              <p>U slučajevima govora koji poziva na nasilje i mržnju i time podliježe kaznenopravnom progonu prema čl. 325. Kaznenog zakona (NN 125/11, 144/12, 56/15, 61/15), nadležno je Državno odvjetništvo Republike Hrvatske.</p>
              <p>U slučajevima djela motiviranih mržnjom, administratori stranice će se bez odgode javiti nadležnim institucijama, prvenstveno policiji, državnom odvjetništvu i prema potrebi Pučkoj pravobraniteljici ili ostalim posebnim pravobraniteljstvima.</p>
             </div>
  },{
    title: "O nama",
    content: <div>
                <div>
                  <a href="https://eeagrants.org/Who-we-are/Norway-Grants"><img width="300" src={norway_grants} alt="Fondovi Europskog gospodarskog prostora i Kraljevine Norveške" /></a>
                </div>
                <br />
                <p>Stranica dostajemrznje.org nastala je u suradnji Centra za mirovne studije, GONG-a i Kuće ljudskih prava Zagreb, kao odgovor na učestalu prisutnost govora mržnje koji razorno djeluje na društvenu koheziju, vrijednosti pluralizma, interkulturalnosti i tolerancije prema drugima i drugačijima.</p>
                <p>Stranica dostajemrznje.org nastala je 2016. godine u sklopu projekta 'Dosta je mržnje!' uz financijsku podršku Fondova Europskog gospodarskog prostora i Kraljevine Norveške za organizacije civilnoga društva, čiji je provoditelj za Republiku Hrvatsku Nacionalna zaklada za razvoj civilnoga društva. Stranica je nadograđena kroz projekt „Činjenicama protiv mržnje“, a oba projekta su financirana iz Programa o pravima, jednakosti i građanstvu Europske unije (2014.-2020.) te su realizirani u suradnji s Ministarstvom pravosuđa Republike Finske.</p>
                <p>Nastavak rada stranice u razdoblju od 1.10.2022. – 31.12.2023. omogućen je kroz projekt „Jačanje sustava zaštite i osnaživanje zajednica za prepoznavanje i suzbijanje govora mržnje u Hrvatskoj” koji je podržan kroz Fond za aktivno građanstvo, sredstvima Islanda, Lihtenštajna i Norveške u okviru EGP grantova.</p>
                <div className="logos">
                  <a href="https://ec.europa.eu/info/policies/justice-and-fundamental-rights_en" id="eu_logo" ><img src={eu_logo} alt="logotip Europske Unije" /></a>
                  <a href="https://udruge.gov.hr/" id="uzuvrh_logo" ><img src={uzuvrh_logo} alt="logotip Ureda za udruge Vlade Republike Hrvatske" /></a>
                  <a href="https://www.zagreb.hr/"><img src={zagreb_logo} alt="Logotip grada Zagreba" /></a>
                  <a href="https://oikeusministerio.fi/en/frontpage"><img src={finland_logo} alt="Logotip Ministarstva pravosuđa Republike Finske" /></a>
                  <a href="http://www.kucaljudskihprava.hr/"><img src={kljp_logo} alt="Logotip Kuće ljudskih prava" /></a>
                  <a href="https://www.gong.hr/hr/"><img src={gong_logo} alt="Logotip organizacije GONG" /></a>
                  <a href="https://www.cms.hr/"><img src={cms_logo} alt="Logotip organizacije Centar za mirovne studije" /></a>
                  <a href="https://zaklada.civilnodrustvo.hr/category/104/subcategory/309"><img src={centri_znanja_logo} alt="Logotip Centara znanja za društveni razvoj" /></a>

                  <a href="https://humanrightshouse.org/"><img src={hrhf_logo} alt="Human Rights House Foundation" /></a>
                  <a href="https://zaklada.civilnodrustvo.hr/" id="zaklada_logo"><img src={zaklada_logo} alt="Logotip Centara znanja za društveni razvoj" /></a>
                  <a href="https://zagreb-pride.net/"><img src={pride_logo} alt="ZG pride" /></a>

                  <img src={fah_eu} alt="Facts against hate" />
                </div>
                <p>Sadržaj ove stranice odražava stajališta autora i isključiva je odgovornost autora. Europska komisija nije odgovorna za eventualnu upotrebu informacija koje ova stranica sadržava.</p>
                <p>Projekt "Protiv mržnje" su sufinancirali Ured za udruge Vlade Republike Hrvatske i Grad Zagreb te stajališta izražena na ovoj stranici predstavljaju isključivu odgovornost autora i ne odražavaju nužno stajališta Ureda za udruge Vlade RH niti Grada Zagreba.</p>
                <p>Nastavak rada ove stranice omogućen je financijskom podrškom Islanda, Lihtenštajna i Norveške u okviru EGP grantova. Sadržaj ove stranice isključiva je odgovornost Kuće ljudskih prava i ne odražava nužno stavove država donatorica i Upravitelja Fonda.</p>
              </div>

  },{
    title: "O djelima motiviranim mržnjom",
    content: <div>
      <p>Djela motivirana mržnjom u Kaznenom zakonu se zovu zločini iz mržnje i definirani su čl. 87(21) kao kaznena djela počinjena zbog rasne pripadnosti, boje kože, vjeroispovijesti, nacionalnog ili etničkog podrijetla, jezika, invaliditeta, spola, spolnog opredjeljenja ili rodnog identiteta druge osobe. To su djela motivirana predrasudama prema određenim društvenim skupinama.</p>
      <p>Međutim, radi se o kaznenim djelima čije žrtve nisu samo pojedinci nego i zajednice odnosno grupe kojima žrtve pripadaju, a s kojima dijele određene karakteristike: rasa, boje kože, vjeroispovijest, nacionalno ili etničko podrijetlo, jezik, invaliditet, spol, spolno opredjeljenje ili rodni identitet). Takvim djelima želi se poslati poruka o nepoželjnosti ili inferiornosti društvene skupine prema kojoj je zločin uperen. Zbog navedenog, zločini iz mržnje se nazivaju još i signalni zločini ili zločini s porukom, a činjenica da je takvo djelo počinjeno iz mržnje predstavlja otegotnu okolnost prilikom izricanja kazne za takav čin.</p>
      <p>Prisutnost ovakvih djela ukazuje na nerješene probleme netrpeljivosti u društvu jer je fizičko nasilje često samo vrh ledene sante koju čine diskriminacija i govor mržnje te koji utiru put i olakšavaju, opravdavaju ili pozivaju na nasilje i mržnju.</p>
      <p>Međutim, nije svako nasilje počinjeno radi navedenih karakteristika zločin iz mržnje u kaznenopravnom smislu. Postoje prekršaji iz drugih zakona koji su vezani uz motiv mržnje. Ispravno kvalificiranje djela zadatak je nadležnih institucija - policije, tužiteljstva i sudova.</p>
    </div>
  }]

  render() {

    return (
      <main>
        <h3>{this.texts[this.props.text - 1].title}</h3>
        {this.texts[this.props.text - 1].content}
      </main>
    );
  }
}

export default ScreenText;
