import React, { Component } from 'react'

class Screen2 extends Component {

  render() {

    const { entry, goNext, addEntry } = this.props
    const forms = ['incident na sportskom natjecanju', 'objava na društvenoj mreži ili internetu', 'objava u medijima', 'obilježja na javnoj površini', 'govor na javnom skupu']

    return (
      <main>
        <h3>Vrsta prijave</h3>
        {forms.map(form =>
          <button className="black square text-hover text-hover-fill-opening-horizontal" disabled={entry.form === form} onClick={() => {goNext(); addEntry('form', form)}}>{form}</button>
        )}
      </main>
    );
  }
}

export default Screen2;
