import React, { Component } from 'react'

class Screen8A extends Component {

  state = {}

  yes = () => {
    this.props.addEntry('name', undefined, null)
    this.props.addEntry('email', undefined, null)
    this.setState({consent: true})
  }

  cancel = () => {
    this.props.addEntry('name','', null)
    this.props.addEntry('email','', null)
    this.setState({consent: false})
  }

  render() {

    const { entry, addEntry } = this.props

    return (
      <main>
        <h3>Djelo motivirano mržnjom</h3>

        <label>U slučaju potrebe za više detalja o prijavi, možemo li vas kontaktirati?</label>
        <button className="square orange" onClick={this.yes}>DA</button>
        <button className="square" onClick={this.cancel}>NE</button>

        { this.state.consent === true &&
          <div>
            <label>Ime
              <input type="text" value={entry.name} onChange={(e) => addEntry('name', null, e)} />
            </label>
            <label>Email
              <input type="text" value={entry.email} onChange={(e) => addEntry('email', null, e)} />
            </label>
            <p style={{fontSize: 13}}>
              <strong>Pristanak za pohranu i obradu osobnih podataka</strong>
              <br />
              Brinemo o vašim osobnim podacima. Naše politika zaštite osobnih podataka usklađene su s odredbama Opće uredbe o zaštiti podataka (General Data Protection Regulation – GDPR, 95/46/EC). Svi osobni podaci prikupljaju se isključivo za potrebe postupanja po ovoj prijavi i neće biti korišteni za druge svrhe, niti dijeljeni s trećim stranama. Više informacija o osobnim podacima možete pronaći ovdje: <a style={{color: 'black'}} href="https://bit.ly/36okD2t" rel="noopener noreferrer" target="_blank">https://bit.ly/36okD2t</a>.
            </p>
          </div>
        }
        { this.state.consent === false &&
        <div>
          <p>Budući da ne želite ostaviti kontakt, Vaša prijava bit će spremljena u našu bazu i služit će nam isključivo za statističku svrhu.</p>
        </div>
        }
      </main>
    );
  }
}

export default Screen8A;
