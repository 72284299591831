import React, { Component } from 'react'

class Screen4 extends Component {

  render() {

      const { entry, addEntry } = this.props

      return (
      <main>
        <h3>{entry.form}</h3>
        <div>
          <p>Molimo navedite što više detalja relevantnih za Vašu prijavu, kako bi administratorski tim mogao s njom što temeljitije postupati. Ukoliko prilažete multimedijske sadržaje, molimo da oni budu u što je moguće kompaktnijem formatu, kako ne bi zauzimali previše prostora i kako ne bi bilo problema s njihovim prijenosom.</p>
          <label>Naslov
            <input type="text" value={entry.title} onChange={(e) => addEntry('title', null, e)} />
          </label>
          <label>Opis
            <textarea onChange={(e) => addEntry('description', null, e)}>{entry.description}</textarea>
          </label>
        </div>
      </main>
    );
  }
}

export default Screen4;
