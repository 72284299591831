import React, { Component } from 'react'

class Screen6 extends Component {

  render() {

    const { addEntry } = this.props

    return (
      <main className="edit_screen">
        <h3>Fotografija ili screenshoot</h3>
        <p style={{marginBottom: 40}}>Ukoliko njima raspolažete, priložite i dokaze - multimedijske datoteke (fotografiju ili screenshoot) kao prilog vašoj prijavi.</p>
        <label>Fotografije
          <input type="file" onChange={(e) => addEntry('photo_1', null, e)} />
          <input type="file" onChange={(e) => addEntry('photo_2', null, e)} />
          <input type="file" onChange={(e) => addEntry('photo_3', null, e)} />
        </label>
      </main>
    );
  }
}

export default Screen6;
