import React, { Component } from 'react'

class Screen5A extends Component {

  render() {

    const { entry, addEntry } = this.props

    return (
      <main>
        <h3>Djelo motivirano mržnjom</h3>
        <label>Opišite žrtvu(e) *
          <input type="text" value={entry.victim} onChange={(e) => addEntry('victim', null, e)} />
        </label>
        <p className="hint">Navedite primjerice spol žrtve, stas, izgled, eventualna vidljiva i uočljiva obilježja – pripadnost drugoj rasi, vjerska obilježja i sl.</p>
        <label>Opišite počinjeno djelo svojim riječima. *
          <input type="text" value={entry.crime} onChange={(e) => addEntry('crime', null, e)} />
        </label>
        <p className="hint">Ne trebate unositi zakonske kvalifikacije. Navedite primjerice gdje ste bili i što ste radili u trenutku događaja, kako vam je / su napadač/i prišli, je li vam nešto rečeno, kojim tonom vam je rečeno i što se dalje desilo.</p>
        <label>Opišite napadača(e) *
          <input type="text" value={entry.attacker} onChange={(e) => addEntry('attacker', null, e)} />
        </label>
        <p className="hint">Navedite primjerice spol napadača, stas, izgled, eventualna vidljiva i uočljiva obilježja – od fizičkih do pripadnosti određenim grupacijama, vjerska obilježja i sl.</p>
      </main>
    );
  }
}

export default Screen5A;
