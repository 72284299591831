import React, { Component } from 'react'

class Arrow extends Component {

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="54.521" height="45.478" viewBox="0 0 54.521 45.478" className={this.props.right ? "right" : ""}>
        <g id="Group_108" data-name="Group 108" transform="translate(-128.701 -567.821)">
          <line id="Line_11" data-name="Line 11" x2="44.692" transform="translate(134.53 590.56)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="8"/>
          <g id="Group_107" data-name="Group 107" transform="translate(134.358 590.56) rotate(-45)">
            <line id="Line_12" data-name="Line 12" y2="24.158" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="8"/>
            <line id="Line_13" data-name="Line 13" y2="24.158" transform="translate(24.158 0) rotate(90)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="8"/>
          </g>
        </g>
      </svg>
    );
  }
}

export default Arrow;
