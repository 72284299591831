import React, { Component } from 'react'

class Screen5 extends Component {

  render() {

      const { entry, addEntry } = this.props

      const forms = ['incident na sportskom natjecanju', 'objava na društvenoj mreži ili internetu', 'objava u medijima', 'obilježja na javnoj površini', 'govor na javnom skupu']

      let form = ''

      switch (entry.form) {
        case forms[0]:
          form = <div>
            <p style={{marginBottom: 65}}>Molimo, popunite osnovne informacije o neprihvatljivom govoru (npr. nedopustivom skandiranju) ili neprihvatljivim obilježjima istaknutima na sportskom natjecanju.</p>
            <label>Vrijeme sportskog natjecanja
              <input type="text" value={entry.time} onChange={(e) => addEntry('time', null, e)} />
            </label>
            <label>Molimo navedite oblik u kojem je došlo do incidenta na sportskom natjecanju
              <select value={entry.sport_type} onChange={(e) => addEntry('sport_type', null, e)}>
                <option></option>
                <option>Istaknuti neprihvatljivi simboli ili rekviziti</option>
                <option>Neprihvatljivo skandiranje</option>
              </select>
            </label>
          </div>
          break;
        case forms[1]:
          form =<div>
            <p style={{marginBottom: 65}}>Molimo, popunite osnovne informacije o neprihvatljivom govoru na društvenoj mreži.</p>
            <label>Društvena mreža
            <select value={entry.network} onChange={(e) => addEntry('network', null, e)}>
              <option></option>
              <option>Facebook</option>
              <option>Twitter</option>
              <option>Instagram</option>
              <option>Youtube</option>
              <option>Google+</option>
              <option>Snapchat</option>
              <option>Dailymotion</option>
              <option>jeuxvideo.com</option>
              <option>neka druga društvena mreža</option>
            </select>
            </label>
            <label>Osnova neprihvatljivog govora:
              <select value={entry.network_type} onChange={(e) => addEntry('network_type', null, e)}>
                <option></option>
                <option>Nacionalnost ili etničko porijeklo</option>
                <option>Spol</option>
                <option>Jezik</option>
                <option>Vjeroispovijest ili nereligioznost</option>
                <option>Seksualna orijentacija ili rodni identitet</option>
                <option>Boja kože</option>
                <option>Izbjeglički ili migrantski status</option>
                <option>Dob</option>
                <option>Zdravstveno stanje ili invaliditet</option>
                <option>Bračni status</option>
                <option>Obrazovanje, socijalno porijeklo ili imovinsko stanje</option>
              </select>
            </label>
            <label>Link (ovo polje je obavezno za unos)
              <input type="text" value={entry.url} onChange={(e) => addEntry('url', null, e)} placeholder="http://" />
            </label>
          </div>
          break;
        case forms[2]:
          form = <div>
            <p style={{marginBottom: 15}}>Molimo, popunite osnovne informacije o neprihvatljivom govoru primijećenom u medijskoj objavi.</p>
            <label>U kakvom se mediju neprihvatljiv govor pojavljuje?
              <select value={entry.media_source} onChange={(e) => addEntry('media_source', null, e)}>
                <option></option>
                <option>TV</option>
                <option>Radio</option>
                <option>Tiskani medij</option>
                <option>Internetski portal</option>
                <option>Forum</option>
                <option>Druga internetska stranica</option>
            </select>
            </label>
            <label>Molimo navedite o kojem se mediju i na kojem mjestu radi
              <input type="text" value={entry.media_name} onChange={(e) => addEntry('media_name', null, e)} />
            </label>
            <label>Osnova neprihvatljivog izražavanja:
              <select value={entry.media_type} onChange={(e) => addEntry('media_type', null, e)}>
                <option></option>
                <option>Nacionalnost ili etničko porijeklo</option>
                <option>Spol</option>
                <option>Jezik</option>
                <option>Vjeroispovijest ili nereligioznost</option>
                <option>Seksualna orijentacija ili rodni identitet</option>
                <option>Boja kože</option>
                <option>Izbjeglički ili migrantski status</option>
                <option>Dob</option>
                <option>Zdravstveno stanje ili invaliditet</option>
                <option>Bračni status</option>
                <option>Obrazovanje, socijalno porijeklo ili imovinsko stanje</option>
              </select>
            </label>
          </div>
          break;
        case forms[3]:
          form = <div>
            <p style={{marginBottom: 15}}>Molimo, popunite osnovne informacije o neprihvatljivim obilježjima primijećenima na javnoj površini.</p>
            <label>O kakvom se obliku neprihvatljivih obilježja na javnoj površini radi?
              <select value={entry.public_object} onChange={(e) => addEntry('public_object', null, e)}>>
                <option></option>
                <option value="Grafit">Grafit</option>
                <option value="Simbol mržnje">Simbol mržnje</option>
                <option value="Plakat">Plakat</option>
                <option value="Oglas">Oglas</option>
                <option value="Oskvrnuće ili uništenje spomenika ili objekta">Oskvrnuće ili uništenje spomenika ili objekta</option>
            </select>
            </label>
            <label>Lokacija javne površine
              <input type="text" value={entry.public_place} onChange={(e) => addEntry('public_place', null, e)} />
            </label>
            <label>Osnova neprihvatljivog izražavanja:
              <select value={entry.public_type} onChange={(e) => addEntry('public_type', null, e)}>
                <option></option>
                <option>Nacionalnost ili etničko porijeklo</option>
                <option>Spol</option>
                <option>Jezik</option>
                <option>Vjeroispovijest ili nereligioznost</option>
                <option>Seksualna orijentacija ili rodni identitet</option>
                <option>Boja kože</option>
                <option>Izbjeglički ili migrantski status</option>
                <option>Dob</option>
                <option>Zdravstveno stanje ili invaliditet</option>
                <option>Bračni status</option>
                <option>Obrazovanje, socijalno porijeklo ili imovinsko stanje</option>
              </select>
            </label>
          </div>
          break;
        case forms[4]:
          form = <div>
            <p style={{marginBottom: 15}}>Molimo, popunite osnovne informacije o neprihvatljivom izražavanju primijećenom na javnom skupu.</p>
            <label>O kakvom se javnom skupu radi?
              <select value={entry.meeting_object} onChange={(e) => addEntry('meeting_object', null, e)}>>
                <option></option>
                <option>Prosvjed</option>
                <option>Koncert</option>
                <option>Javna manifestacija</option>
                <option>Nešto drugo</option>
            </select>
            </label>
            <label>Lokacija skupa ili javnog nastupa
              <input type="text" value={entry.meeting_place} onChange={(e) => addEntry('meeting_place', null, e)} />
            </label>
            <label>Osnova neprihvatljivog izražavanja:
              <select value={entry.meeting_type} onChange={(e) => addEntry('meeting_type', null, e)}>
                <option></option>
                <option>Nacionalnost ili etničko porijeklo</option>
                <option>Spol</option>
                <option>Jezik</option>
                <option>Vjeroispovijest ili nereligioznost</option>
                <option>Seksualna orijentacija ili rodni identitet</option>
                <option>Boja kože</option>
                <option>Izbjeglički ili migrantski status</option>
                <option>Dob</option>
                <option>Zdravstveno stanje ili invaliditet</option>
                <option>Bračni status</option>
                <option>Obrazovanje, socijalno porijeklo ili imovinsko stanje</option>
              </select>
            </label>
          </div>
          break;
        default:
          form = null
      }


    return (
      <main>
        <h3>{entry.form}</h3>
        {form}
      </main>
    );
  }
}

export default Screen5;
