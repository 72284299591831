import React, { Component } from 'react'
import '../stylesheets/hamburgers.css'

class Hamburger extends Component {

    render() {

    const {active, toogleMenu} = this.props

    return (
      <button className={active ? "hamburger hamburger--squeeze is-active" : "hamburger hamburger--squeeze"}
              aria-label="Menu"
              onClick={() => toogleMenu()}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    );
  }
}

export default Hamburger;

/*
hamburger-types
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spring,
  spring-r,
  stand,
  stand-r,
  spin,
  spin-r,
  squeeze,
  vortex,
  vortex-r
  */
