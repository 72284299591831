import React, { Component } from 'react'

class Menu extends Component {

  render() {
    return <div className="menu">
            <ul>
              <li><button className="text-hover text-hover-underline-opening" onClick={() => this.props.showText(1)}>O govoru mržnje</button></li>
              <li><button className="text-hover text-hover-underline-opening" onClick={() => this.props.showText(5)}>O djelima motiviranim mržnjom</button></li>
              <li><button className="text-hover text-hover-underline-opening" onClick={() => this.props.showText(2)}>Kako prijaviti?</button></li>
              <li><button className="text-hover text-hover-underline-opening" onClick={() => this.props.showText(3)}>Često postavljena pitanja</button></li>
              <li><button className="text-hover text-hover-underline-opening" onClick={() => this.props.showText(4)}>O nama</button></li>
            </ul>
          </div>
  }
}

export default Menu;
