import React, { Component } from 'react'

class Screen3 extends Component {

  render() {

    const { entry, addEntry, setAnonymous, screenAlert } = this.props

    const popup = <div className="popup">
        <p>Niste ostavili Vašu e-mail adresu.</p>
        <p>Prijavu možete ispuniti i bez ostavljanja e-mail adrese.</p>
        <p>Ukoliko ne ostavite email, nećete moći dobiti povratnu informaciju o postupanju vezano za vašu prijavu.</p>
        <button onClick={setAnonymous}>&#10005;</button>
      </div>

    return (
      <main>
        <h3>{entry.form}</h3>
        <p style={{ marginBottom: 10 }}>Molimo navedite Vaše kontakt podatke kako bismo mogli javiti status i promjene u vašoj prijavi.</p>
        <p style={{ marginBottom: 36 }}>Napominjemo kako prijavu možete poslati i anonimno, no u tom slučaju administratori stranice će moći postupati po prijavi, ali neće biti u mogućnosti vama dati povratnu informaciju o postupanju.</p>
        <label>E-mail adresa (opcionalno)
          <input type="text" value={entry.email} onChange={(e) => addEntry('email', null, e)} />
        </label>
        <label>Vaše ime/alias
          <input type="text" value={entry.alias} onChange={(e) => addEntry('alias', null, e)} />
        </label>
        {screenAlert === 3 ? popup : null}
      </main>
    );
  }
}

export default Screen3;
