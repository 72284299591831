import React, { Component } from 'react'

class Screen4A extends Component {

  render() {

    const { entry, addEntry } = this.props

    return (
      <main>
        <h3>Djelo motivirano mržnjom</h3>
        <label>Kako ste saznali za djelo? *
          <input type="text" value={entry.source} onChange={(e) => addEntry('source', null, e)} />
        </label>

        <h4>Po kojoj osnovi mislite da je počinjeno djelo? *</h4>

        <div className="checkbox">
          <input id="check_1" type="checkbox" onChange={(e) => addEntry('basis', 'Rasa / boja kože', e)} />
          <label for="check_1">Rasa / boja kože</label>
        </div>

        <div className="checkbox">
          <input id="check_2" type="checkbox" onChange={(e) => addEntry('basis', 'Vjeroispovijest', e)} />
          <label for="check_2">Vjeroispovijest</label>
        </div>

        <div className="checkbox">
          <input id="check_3" type="checkbox" onChange={(e) => addEntry('basis', 'Nacionalno ili etničko podrijetlo', e)} />
          <label for="check_3">Nacionalno ili etničko podrijetlo</label>
        </div>

        <div className="checkbox">
          <input id="check_4" type="checkbox" onChange={(e) => addEntry('basis', 'Jezik', e)} />
          <label for="check_4">Jezik</label>
        </div>

        <div className="checkbox">
          <input id="check_5" type="checkbox" onChange={(e) => addEntry('basis', 'Invaliditet', e)} />
          <label for="check_5">Invaliditet</label>
        </div>

        <div className="checkbox">
          <input id="check_6" type="checkbox" onChange={(e) => addEntry('basis', 'Spol', e)} />
          <label for="check_6">Spol</label>
        </div>

        <div className="checkbox">
          <input id="check_7" type="checkbox" onChange={(e) => addEntry('basis', 'Seksualna orijentacija', e)} />
          <label for="check_7">Seksualna orijentacija</label>
        </div>

        <div className="checkbox">
          <input id="check_8" type="checkbox" onChange={(e) => addEntry('basis', 'Rodni identitet', e)} />
          <label for="check_8">Rodni identitet</label>
        </div>

      </main>
    );
  }
}

export default Screen4A;
