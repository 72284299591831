import React, { Component } from 'react'
import axios from 'axios'

class Screen1 extends Component {

  state = {
    check: false,
    email: '',
    uid: ''
  }

  openCheck = () => {
    this.setState({check: true})
  }

  addValue = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  submit = () => {
    const app = this
    let dontSubmit = false

    if(this.state.email === '') {
      dontSubmit = true
      this.setState({emailError: true})
    } else {
      this.setState({emailError: null})
    }

    if(this.state.uid === '') {
      dontSubmit = true
      this.setState({uidError: true})
    } else {
      this.setState({uidError: null})
    }

    if(!dontSubmit){
      axios.post('https://dostajemrznje.org/api/check', {
        email: this.state.email,
        uid: this.state.uid
      })
      .then(function (response) {
        if(response.data.status !== 'ok') {
          alert('Prijava s tim brojem ne postoji!\nProvjerite da li ste upisali ispravan broj prijave.')
        } else {
          app.setState({check: false, email: '', uid: ''})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }


  render() {

    const { goNext } = this.props

    const start = <main>
      <p><strong>Dobrodošli na DostaJeMrznje.org, alat za prijavljivanje djela motiviranih mržnjom, govora mržnje u javnom prostoru, na društvenim mrežama, internetu i u medijima!</strong></p>
      <button className="orange round" onClick={() => goNext()}>prijavi govor mržnje</button>
      <button className="orange round smaller" onClick={() => goNext(1,2)}>prijavi djelo motivirano mržnjom</button>
      <button className="blue square text-hover text-hover-fill-opening-horizontal" onClick={() => this.openCheck()}>provjeri status prijave</button>
    </main>

    const check = <main>
      <div style={{textAlign: 'right', marginTop: 20}}><button className="red small_square" onClick={() => this.setState({check: false})}>x</button></div>
      <p><strong>Molimo, unesite Vašu e-mail adresu i broj prijave kako bi vam poslali informacije o statusu.</strong></p>
      <label>E-mail adresa
        <input type="text" name="email" className={this.state.emailError ? "has_error" : null} onChange={(e) => this.addValue(e)} />
      </label>
      <label>Broj prijave
        <input type="text" name="uid" className={this.state.uidError ? "has_error" : null} onChange={(e) => this.addValue(e)} />
      </label>
      <button className="black square" onClick={() => this.submit()}>Pošalji</button>
    </main>

    return this.state.check ? check : start
  }
}

export default Screen1;
