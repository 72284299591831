import React, { Component } from 'react'
import Arrow from './Arrow'

class Arrows extends Component {

  dotClass(dot_step) {
    const current_step = this.props.step
    if(dot_step === current_step) {
      return "active"
    } else if(dot_step < current_step) {
      return "previous"
    } else if(dot_step > current_step) {
      return "next"
    }
  }

  blank(obj) {
    return (obj === undefined || obj.length === 0)
  }

  render() {

    const { entry, step, app, max, goNext } = this.props

    const disable_next_step = step === max ||
                              (app === 1 && entry.form === undefined) ||
                              (app === 1 && step === 4 && entry.description === undefined) ||
                              (app === 1 && step === 5 && entry.form === 'objava na društvenoj mreži ili internetu' && entry.url === undefined) ||
                              (app === 1 && step === 6 && entry.form === 'obilježja na javnoj površini' && entry.photo_1 === undefined && entry.photo_2 === undefined && entry.photo_3 === undefined) ||
                              (app === 2 && step === 2 && (this.blank(entry.date) || this.blank(entry.place))) ||
                              (app === 2 && step === 4 && (this.blank(entry.source) || this.blank(entry.basis))) ||
                              (app === 2 && step === 5 && (this.blank(entry.victim) || this.blank(entry.crime) || this.blank(entry.attacker))) ||
                              (app === 2 && step === 6 && (this.blank(entry.why) || this.blank(entry.consequences) || this.blank(entry.other_notification))) ||
                              (app === 2 && step === 8 && entry.email === undefined)

    const stepper = step > 1 && step < max ? <div>
      <button className="arrow" disabled={step === 1} onClick={() => goNext(-1, app)}><Arrow /></button>
        <span className="dots">
          {Array.from(Array(max-1).keys()).map(x =>
            <dot className={this.dotClass(x+2)}></dot>
          )}
        </span>
      <button className="arrow" disabled={disable_next_step} onClick={() => goNext(1, app)}><Arrow right="1" /></button>
    </div> : null

    return (
      <footer>
        {stepper}
      </footer>
    );
  }
}

export default Arrows;
