import React, { Component } from 'react'
import edit from '../../images/edit.svg'

class Screen6 extends Component {

  render() {

    const { entry, goTo } = this.props

    return (
      <main className="edit_screen">
        <h3>Provjera prijave</h3>

        <div>
          <img src={edit} alt="Uredi" onClick={() => goTo(2)} />
          <h4>Vrsta prijave:</h4>
          <p>{entry.form}</p>
          <hr />
        </div>

        <div>
          <img src={edit} alt="Uredi" onClick={() => goTo(3)} />
          <h4>Vaši podaci:</h4>
          <p>{entry.email}<br />{entry.alias}</p>
          <hr />
        </div>

        <div>
          <img src={edit} alt="Uredi" onClick={() => goTo(4)} />
          <h4>Opis:</h4>
          <p>{entry.title}</p><p>{entry.description}</p>
          <hr />
        </div>

        <div>
          <img src={edit} alt="Uredi" onClick={() => goTo(5)} />
          <h4>Dodatno:</h4>
          <p></p>
          {entry.time ? <p>Vrijeme sportskog natjecanja:<br />{entry.time}</p> : null}
          {entry.sport_type ? <p>Oblik incidenta:<br />{entry.sport_type}</p> : null}
          {entry.network ? <p>Društvena mreža:<br />{entry.network}</p> : null}
          {entry.network_type ? <p>Osnova neprihvatljivog govora:<br />{entry.network_type}</p> : null}
          {entry.media_source ? <p>Vrsta medija:<br />{entry.media_source}</p> : null}
          {entry.media_name ? <p>Naziv medija: {entry.media_name}</p> : null}
          {entry.media_type ? <p>Osnova neprihvatljivog govora:<br />{entry.media_type}</p> : null}
          {entry.public_object ? <p>Oblik neprihvatljivih obilježja:<br />{entry.public_object}</p> : null}
          {entry.public_place ? <p>Lokacija javne površine:<br />{entry.public_place}</p> : null}
          {entry.public_type ? <p>Osnova neprihvatljivog govora:<br />{entry.public_type}</p> : null}
          {entry.meeting_object ? <p>Vrsta javnog skupa:<br />{entry.meeting_object}</p> : null}
          {entry.meeting_place ? <p>Lokacija javnog skupa:<br />{entry.meeting_place}</p> : null}
          {entry.meeting_type ? <p>Osnova neprihvatljivog govora:<br />{entry.meeting_type}</p> : null}
          <hr />
        </div>

        <div>
          <img src={edit} alt="Uredi" onClick={() => goTo(6)} />
          <h4>Fotografija ili screenshoot</h4>
          <p>{entry.photo_1 ? "1. Fotografija" : null}</p>
          <p>{entry.photo_2 ? "2. Fotografija" : null}</p>
          <p>{entry.photo_3 ? "3. Fotografija" : null}</p>
        </div>

      </main>
    );
  }
}

export default Screen6;
