import React, { Component } from 'react'
import Hamburger from './Hamburger'
import Arrows from './Arrows'
import Menu from './Menu'
import Screen1 from './Screen1'
import Screen2 from './app1/Screen2'
import Screen3 from './app1/Screen3'
import Screen4 from './app1/Screen4'
import Screen5 from './app1/Screen5'
import Screen6 from './app1/Screen6'
import Screen7 from './app1/Screen7'
import Screen8 from './app1/Screen8'
import Screen2A from './app2/Screen2'
import Screen3A from './app2/Screen3'
import Screen4A from './app2/Screen4'
import Screen5A from './app2/Screen5'
import Screen6A from './app2/Screen6'
import Screen7A from './app2/Screen7'
import Screen8A from './app2/Screen8'
import Screen9A from './app2/Screen9'
import ScreenText from './ScreenText'
import logo from '../images/logo.png'
import '../stylesheets/constants.sass'
import '../stylesheets/app.sass'
import '../stylesheets/buttons.sass'
import '../stylesheets/media_queries.sass'
import '../stylesheets/text_hover.min.css'

class App extends Component {

  state = {
    menuActive: false,
    step: 1,
    screenAlert: false,
    entry: {},
    basis: []
  }

  addEntry = (key, value, e) => {
    if(value === null) value = e.target.value
    if(e && e.target.files) {
      value = e.target.files[0]
    }
    if(e && e.target.type === "checkbox"){
      if(e.target.checked) {
        this.setState({
          basis: [...this.state.basis, value]
        })
      } else {
        const index = this.state.basis.indexOf(value)
        if (index > -1) {
          this.state.basis.splice(index, 1)
        }
      }
      setTimeout(() => {
        this.setState(prevState => ({
          entry: {
            ...prevState.entry,
            [key]: this.state.basis
          }
        }))
      }, 100)
    } else {
      this.setState(prevState => ({
        entry: {
          ...prevState.entry,
          [key]: value
        }
      }))
    }
  }

  toogleMenu = () => {
    this.setState({menuActive: this.state.menuActive ? false : true})
  }

  goNext = (no=1, app=1) => {
    if(this.state.step === 3 && no === 1 && app === 1 && (this.state.entry.email === undefined || this.state.entry.email === '')) {
      this.setState({screenAlert: 3})
    } else {
      this.setState({step: this.state.step + no, app: app})
    }
  }

  showText = (text) => {
    this.setState({step: 9, text: text, menuActive: false})
  }

  goTo = (step) => {
    if(step === 1){
      this.setState({entry: {}, app: 1})
    }
    this.setState({step: step})
  }

  setAnonymous = () => {
    this.addEntry('email', 'anonymous')
    this.setState({screenAlert: false})
  }

  render() {

    const { step, app, entry, menuActive, screenAlert, text } = this.state

    const appPrefix = app === 2 ? 8 : 0

    const screens = [<Screen1 goNext={this.goNext} />,
                    <Screen2 entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen3 addEntry={this.addEntry} setAnonymous={this.setAnonymous} screenAlert={screenAlert} entry={entry} />,
                    <Screen4 entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen5 entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen6 entry={entry} goNext={this.goNext} addEntry={this.addEntry}  />,
                    <Screen7 entry={entry} goTo={this.goTo} />,
                    <Screen8 entry={entry} goTo={this.goTo} />,
                    <ScreenText text={text} />,
                    <Screen2A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen3A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen4A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen5A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen6A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen7A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen8A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />,
                    <Screen9A entry={entry} goNext={this.goNext} addEntry={this.addEntry} />
                    ]

    return (
      <div className="app">
        <header>
          {menuActive ? null : <img src={logo} alt="Dosta je mržnje" onClick={() => this.goTo(1)} />}
            <Hamburger active={menuActive} toogleMenu={this.toogleMenu} />
        </header>
        {menuActive ? <Menu showText={this.showText} /> : screens[step-1 + appPrefix]}
        {menuActive ? null : <Arrows app={app} step={step} entry={entry} goNext={this.goNext} max={app === 1 ? 8 : 9} />}
      </div>
    );
  }
}

export default App;
