import React, { Component } from 'react'

class Screen2A extends Component {

  render() {

    const { entry, addEntry } = this.props

    return (
      <main>
        <h3>Djelo motivirano mržnjom</h3>
        <label>Datum događaja *
          <input type="text" value={entry.date} onChange={(e) => addEntry('date', null, e)} />
        </label>
        <p className="hint">Upišite točan ili okviran termin događaja</p>
        <label>Mjesto događaja *
          <input type="text" value={entry.place} onChange={(e) => addEntry('place', null, e)} />
        </label>
        <p className="hint">Upišite lokaciju događaja, mjesto, naselje, općinu i/ili županiju.</p>
      </main>
    );
  }
}

export default Screen2A;
