import React, { Component } from 'react'

class Screen7A extends Component {

  render() {

    const { entry, addEntry } = this.props

    return (
      <main>
        <h3>Djelo motivirano mržnjom</h3>
        <label>Dodatne napomene
          <textarea value={entry.note} onChange={(e) => addEntry('note', null, e)}></textarea>
        </label>
        <p className="hint">ukoliko želite nešto dodati što nije bilo obuhvaćeno upitnikom, ovo je prostor za to.</p>
      </main>
    );
  }
}

export default Screen7A;
