import React, { Component } from 'react'

class Screen3A extends Component {

  render() {

    const { entry, addEntry } = this.props

    return (
      <main>
        <h3>Djelo motivirano mržnjom</h3>
        <label>Prilog – dokaz, ukoliko postoji (slika, video, snimak, zvučni zapis i sl.)
          <input type="file" onChange={(e) => addEntry('file', null, e)} />
        </label>
        <label>URL, ukoliko se radi o događaju na internetu
          <input type="text" value={entry.url} onChange={(e) => addEntry('url', null, e)} />
        </label>
      </main>
    );
  }
}

export default Screen3A;
