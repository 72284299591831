import React, { Component } from 'react'
import axios from 'axios'
import greenMark from '../../images/green_mark.svg'

class Screen8 extends Component {

  state = {
    status: <h2>Prijava se šalje...</h2>
  }

  componentDidMount() {
    let app = this
    let formData = new FormData()

    Object.keys(this.props.entry).map(e =>
      formData.set(e, this.props.entry[e])
    )
    axios.post('https://dostajemrznje.org/api/save', formData)
    .then(function (response) {
      app.setState({status: <div>
          <img src={greenMark} alt="Dosta je mržnje" />
          <h2>Uspješno ste podnijeli prijavu</h2>
          <h2>Hvala!</h2>
          <p style={{textAlign: 'center'}}>Administratorski tim postupit će po vašoj prijavi i pružiti vam informacije o statusu prijave.<br />Status prijave možete provjeriti <button style={{fontSize: 16}} onClick={() => app.props.goTo(1)}>ovdje</button>.</p>
        </div>})
    })
    .catch(function (error) {
      app.setState({status: <h3><br /><br />Došlo je do greške kod spremanja vaše prijave!<br /><br />Molimo pokušajte ponovo</h3>})
    });
  }

  render() {

    return (
      <main className="last_screen">
        {this.state.status}
      </main>
    );
  }
}

export default Screen8;
